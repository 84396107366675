<template>
  <!-- eslint-disable vue/camelcase -->
  <div class="settingsPage">
    <div v-if="business" class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <b-tabs>
              <b-tab v-if="user && user.business && (user.business.is_social_review || user.business.is_tap_engine || user.business.is_reply_engine)" title="Settings" :active="!connected_sites_tab">
                <div v-if="user && user.google_accounts && user.google_accounts.length && !default_location" class="mb-4 font-weight-bold text-danger">
                  Please select the Google Location that contain your reviews
                </div>
                <h3>Google Connection</h3>
                <a v-if="user && business" :href="gRedirectLink">
                  <div class="google-btn">
                    <div class="google-icon-wrapper">
                      <img class="google-icon" src="@assets/images/Google__G__logo.png"/>
                    </div>
                    <p class="btn-text"><b>Connect Google Account</b></p>
                  </div>
                </a>
                <div v-if="hasAccessTokenIssues" class="text-danger font-weight-bold mt-2">There was an issue with your Google Account Access Token that is affecting our services. Please Re-connect your Google Account.</div>
                <div class="mb-3"></div>
                <div v-if="user && user.google_accounts && user.google_accounts.length > 1">
                  <label>Google Account</label>
                  <select v-model="selected_account" class="form-control">
                    <option v-for="(account,i) in user.google_accounts" :key="i" :value="account" :selected="selected_account && selected_account.id === account.id" >
                      {{account.account_name}}
                    </option>
                  </select>
                </div>
                <div v-if="selected_account" class="mt-2">
                  <label>Google Location</label>
                  <select v-model="default_location" class="form-control">
                    <option v-for="(location,i) in selected_account.locations" :key="i" :value="location.id" :selected="location.is_default === 1">
                      {{location.title}} {{location.address !== null && location.address.hasOwnProperty('addressLines') && location.address.addressLines.length ? ' - ' + location.address.addressLines.join(' - ') : ''}}
                    </option>
                  </select>
                </div>
                <ValidationObserver
                    v-if="user && user.hasOwnProperty('google_accounts') && user.google_accounts.length"
                    v-slot="{ handleSubmit, invalid, touched }"
                    ref="settingsForm"
                >
                  <form
                      class="authentication-form mt-2"
                      @submit.prevent="handleSubmit(updateSettings)"
                  >

                    <b-form-group>
                      <b-button
                          variant="primary"
                          :disabled="loadingSave || (invalid && touched)"
                          type="submit"
                      >
                        <b-spinner v-if="loadingSave" small />
                        <span v-else>Save</span>
                      </b-button>
                    </b-form-group>
                  </form>
                </ValidationObserver>
                <div v-if="(user.has_premium_plan)" class="mt-5">
                  <ManageTeams v-if="user.role !== 'Team'" />
                  <ValidationObserver
                      v-slot="{ handleSubmit, invalid, touched }"
                      ref="recipientForm"
                  >
                    <form
                        class="authentication-form mt-2"
                        @submit.prevent="handleSubmit(updateEmailSettings)"
                    >
                      <h3 class="mb-0">Email Recipients</h3>
                      <em class="mb-2 small d-block">Separate Multiple recipients with a comma</em>
                      <div v-if="true" class="mt-1">
                        <label class="d-block">New Review Notification</label>
                        <input v-model="user.business.email_settings.new_review_emails" class="form-control" />
                      </div>
                      <div v-if="user.business && user.business.is_social_review" class="mt-1">
                        <label class="d-block">New Post to Social Media Notification</label>
                        <input v-model="user.business.email_settings.new_social_post_emails" class="form-control" />
                      </div>
                      <div v-if="user.business && user.business.is_reply_engine" class="mt-1">
                        <label class="d-block">New Review Replied Notification</label>
                        <input v-model="user.business.email_settings.new_review_replied_emails" class="form-control" />
                      </div>
                      <b-form-group class="mt-3">
                        <b-button
                            variant="primary"
                            :disabled="recipientSaving || (invalid && touched)"
                            type="submit"
                        >
                          <b-spinner v-if="recipientSaving" small />
                          <span v-else>Save</span>
                        </b-button>
                      </b-form-group>
                    </form>
                  </ValidationObserver>
                </div>
                <div v-if="user.business && user.business.is_tap_engine">
                  <h3 class="mt-5">Employee Leaderboard Reports</h3>
                  <em>Reports are sent in EST time. Please adjust accordingly</em><br />
                  Current EST Time: {{new Date().toLocaleString('en-US', { timeZone: 'America/New_York' })}}
                  <ValidationObserver
                      v-slot="{ handleSubmit, invalid, touched }"
                      ref="reportsForm"
                  >
                    <form
                        class="authentication-form mt-2"
                        @submit.prevent="handleSubmit(updateReportSettings)"
                    >
                      <div class="row">
                        <div class="col">
                          <label class="d-block mb-3">Days to send Leaderboard Reports</label>
                          <span v-for="(day,key) in user.business.settings.report_days" :key="key" class="d-inline-block" >
                        <b-form-checkbox v-model="day.sending" size="sm" class="mr-3">{{ day.day }}</b-form-checkbox>
                      </span>

                        </div>
                      </div>
                      <div class="row my-4">
                        <div class="col time-td">
                          <label class="d-block mb-3">Time to send Leaderboard Reports</label>
                          <b-time v-model="user.business.settings.report_time" :minutes-step="15" hide-header></b-time>
                        </div>
                      </div>
                      <b-form-group>
                        <b-button
                            variant="primary"
                            :disabled="loadingSave || (invalid && touched)"
                            type="submit"
                        >
                          <b-spinner v-if="loadingSave" small />
                          <span v-else>Save</span>
                        </b-button>
                      </b-form-group>
                    </form>
                  </ValidationObserver>
                </div>
                <div v-if="user.business && user.business.is_text_requester">
                  <h3 class="mt-5">Time Zone</h3>
                  <ValidationObserver
                      v-slot="{ handleSubmit, invalid, touched }"
                      ref="reportsForm"
                  >
                    <form
                        class="authentication-form mt-2"
                        @submit.prevent="handleSubmit(updateReportSettings)"
                    >
                      <div v-if="user.business && user.business.timezones" class="form-group mt-2">
                        <div class="d-flex align-items-center">
                          <multiselect
                              v-model="user.business.settings.timezone"
                              :options="user.business.timezones"
                              :show-labels="false"
                              class="timezone-select"
                              placeholder="Select Time Zone"
                          ></multiselect>
                          <span class="ml-2">Texts/Emails are sent out between 8AM-5PM in your timezone.</span>
                        </div>
                      </div>
                      <b-form-group>
                        <b-button
                            variant="primary"
                            :disabled="loadingSave || (invalid && touched)"
                            type="submit"
                        >
                          <b-spinner v-if="loadingSave" small />
                          <span v-else>Save</span>
                        </b-button>
                      </b-form-group>
                    </form>
                  </ValidationObserver>
                </div>
              </b-tab>
              <b-tab v-if="user.business && (user.business.is_social_review || user.business.is_social_fusion || user.business.is_insta_stream)" title="Connected Sites" :active="connected_sites_tab">
                <div class="row">
                  <div class="col-3">
                    <div class="card rounded-lg p-3 bg-light">
                      <div class="card-body">
                        <div class="connect-header text-center">
                          <img src="/images/fb-instagram.png" alt="FaceBook & Instagram" height="75" />

                        </div>
                        <div class="text-center my-3">
                          Connect your location's Facebook Account
                        </div>
                        <div class="text-center mt-2">
                          <a v-if="user.business.facebook_token && fb_valid" :href="FBRedirectLink" class="btn btn-grey">Connected</a>
                          <a v-else-if="user.business.facebook_token && !fb_valid" :href="FBRedirectLink" class="btn btn-danger">Integration Expired</a>
                          <a v-else ref="fbConnect" :href="FBRedirectLink" class="btn btn-primary">Connect</a>
                          <small v-if="user.business.facebook_token && !fb_valid" class="d-block mt-2 text-left">
                            There was an error validating the access token. This can happen when a user changes their password, or Facebook has changed their security sessions. Please re-connect your account.
                          </small>
                          <div v-if="fb_valid">
                            <div v-if="user.business.facebook_token" class="mt-3">Facebook Page</div>
                            <div class="d-flex">
                              <select v-if="user.business.facebook_token" v-model="user.business.facebook_token.fb_page_id" class="form-control" placeholder="Please Choose Page">
                                <option v-for="fb_account in user.business.facebook_token.accounts" :key="fb_account.id" :value="fb_account.id">{{fb_account.name}}</option>
                              </select>

                              <button v-if="user.business.facebook_token && user.business.facebook_token.fb_page_id !== initial_facebook_page" class="btn btn-primary ml-2" :disabled="user.business.facebook_token.is_saving" @click="updateFacebookPage">
                                <b-spinner v-if="user.business.facebook_token.is_saving" small />
                                <span v-else>Set</span>
                              </button>
                            </div>
                            <div v-if="igAccounts" class="mt-3">
                              <strong class="d-block">Connected Instagram Account</strong>
                              Name: {{igAccounts.name}}<br />
                              Username: @{{igAccounts.username}}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b-tab>
              <b-tab v-if="user && user.business && user.business.is_social_review && user.business.hasOwnProperty('facebook_token') && user.business.facebook_token" title="Review Social Posts">
                <ValidationObserver
                    v-slot="{ handleSubmit, invalid, touched }"
                    ref="settingsForm"
                >
                  <form
                      class="authentication-form mt-2"
                      @submit.prevent="handleSubmit(updateSocialSettings)"
                  >
                    <div class="form-group d-flex align-center">
                        <b-form-checkbox
                            v-model="user.business.social_settings.auto_sharing_settings.fb_enabled"
                            size="sm"
                            name="fbEnabled"
                            switch
                            inline
                        >
                      </b-form-checkbox>
                      <label for="fbEnabled" >Enable Auto-Sharing on Facebook</label>
                    </div>
                    <div class="form-group d-flex align-center">
                      <b-form-checkbox
                          v-model="user.business.social_settings.auto_sharing_settings.ig_enabled"
                          size="sm"
                          name="fbEnabled"
                          switch
                          inline
                      >
                      </b-form-checkbox>
                      <label for="fbEnabled" >Enable Auto-Sharing on Instagram</label>
                    </div>
                    <div class="form-group d-flex align-items-center">
                      <label>Share new reviews on social media:</label>
                      <multiselect
                          v-model="user.business.social_settings.auto_sharing_settings.share_days"
                          :close-on-select="false"
                          :multiple="true"
                          :show-labels="false"
                          :options="['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']"
                          class="days-select ml-2"
                      ></multiselect>
                    </div>
                    <div class="form-group d-flex align-items-center">
                      <span class="d-block">Between</span> <multiselect
                        v-model="user.business.social_settings.auto_sharing_settings.share_start_time"
                        :options="['12am', '1am', '2am', '3am', '4am', '5am', '6am', '7am','8am', '9am', '10am', '11am', '12pm', '1pm', '2pm', '3pm','4pm', '5pm', '6pm', '7pm', '8pm', '9pm', '10pm', '11pm']"
                        :show-labels="false"
                        class="mx-2 between-select"
                        placeholder="Select Start Time"
                    ></multiselect> and <multiselect
                        v-model="user.business.social_settings.auto_sharing_settings.share_end_time"
                        :options="['12am', '1am', '2am', '3am', '4am', '5am', '6am', '7am','8am', '9am', '10am', '11am', '12pm', '1pm', '2pm', '3pm','4pm', '5pm', '6pm', '7pm', '8pm', '9pm', '10pm', '11pm']"
                        :show-labels="false"
                        class="ml-2 between-select"
                        placeholder="Select End Time"
                    ></multiselect>
                    </div>
                    <div class="form-group d-flex align-items-center">
                      Limit to <input v-model="user.business.social_settings.auto_sharing_settings.share_daily_limit" type="number" min="1" class="form-control max-reviews mx-2" placeholder="No Limit" /> reviews shared per day.
                    </div>
                    <div class="form-group d-flex align-items-center">
                      Minimum review rating to auto-post <input v-model="user.business.social_settings.auto_sharing_settings.minimum_rating" type="number" min="1" max="5" class="form-control max-rating mx-2 text-center" placeholder="Min. Rating" />
                    </div>
                    <div class="form-group d-flex align-items-center">
                      <span>Timezone:</span><multiselect
                          v-model="user.business.social_settings.auto_sharing_settings.timezone"
                          :options="user.business.timezones"
                          :show-labels="false"
                          class="timezone-select ml-2"
                          placeholder="Select Time Zone"
                      ></multiselect>
                    </div>
                    <div class="form-group mt-3">
                      <label class="">Post Text <span v-pre>Available Placeholders: {{rating}}, {{content}}, {{author}}</span></label>
                      <textarea id="social-message" v-model="user.business.social_settings.post_content" rows="6" class="form-control" style="max-width: 500px"></textarea>
                    </div>
                    <b-form-group>
                      <b-button
                          variant="primary"
                          :disabled="loadingSettingsSave || (invalid && touched)"
                          type="submit"
                      >
                        <b-spinner v-if="loadingSettingsSave" small />
                        <span v-else>Save</span>
                      </b-button>
                    </b-form-group>
                  </form>
                </ValidationObserver>
              </b-tab>
              <b-tab v-if="user && user.business && user.business.is_social_fusion && user.business.hasOwnProperty('facebook_token') && user.business.facebook_token" title="Social Media Posts">
                <ValidationObserver
                    v-slot="{ handleSubmit, invalid, touched }"
                    ref="settingsForm"
                >
                  <form
                      class="authentication-form mt-2"
                      @submit.prevent="handleSubmit(updateSocialFusionSettings)"
                  >
                    <div class="form-group d-flex align-center">
                        <b-form-checkbox
                            v-model="user.business.social_fusion_settings.auto_sharing_settings.email_enabled"
                            size="sm"
                            name="emailEnabled"
                            switch
                            inline
                            @change="handleEmailChange"
                        >
                      </b-form-checkbox>
                      <label for="emailEnabled" >Send Email For Post Approval on Facebook and Instagram</label>
                    </div>
                    <div class="form-group d-flex align-center">
                        <b-form-checkbox
                            v-model="user.business.social_fusion_settings.auto_sharing_settings.fb_enabled"
                            size="sm"
                            name="fbEnabled"
                            switch
                            inline
                            @change="handleChange"
                        >
                      </b-form-checkbox>
                      <label for="fbEnabled" >Enable Auto-Sharing on Facebook</label>
                    </div>
                    <div class="form-group d-flex align-center">
                      <b-form-checkbox
                          v-model="user.business.social_fusion_settings.auto_sharing_settings.ig_enabled"
                          size="sm"
                          name="igEnabled"
                          switch
                          inline
                          @change="handleChange"
                      >
                      </b-form-checkbox>
                      <label for="igEnabled" >Enable Auto-Sharing on Instagram</label>
                    </div>
                    <div class="form-group d-flex align-items-center">
                      <label>Social Media Post Types: </label>
                      <multiselect
                          v-model="user.business.social_fusion_settings.auto_sharing_settings.post_type"
                          :close-on-select="false"
                          :multiple="true"
                          :show-labels="false"
                          :options="['Famous Quotes', 'Educational Content', 'Seasonal-Holiday Posts', 'Engagement Posts', 'Fun Facts', 'Humorous Posts', 'Quote of the Day', 'Interactive Content']"
                          class="days-select ml-2"
                      ></multiselect>
                    </div>
                    <div class="form-group d-flex align-items-center">
                      <label>Additional Instructions: </label>
                      <textarea v-model="user.business.social_fusion_settings.auto_sharing_settings.additional_instructions" type="text" class="input-text"
                        placeholder="Provide additional information to further refine the post.">
                      </textarea>
                    </div>
                    <div class="form-group d-flex align-items-center">
                      <label>Generate Social Media Posts: </label>
                      <multiselect
                          v-model="user.business.social_fusion_settings.auto_sharing_settings.share_days"
                          :close-on-select="false"
                          :multiple="true"
                          :show-labels="false"
                          :options="['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']"
                          class="days-select ml-2"
                      ></multiselect>
                    </div>
                    <div class="form-group d-flex align-items-center">
                      <span class="d-block">Between</span> <multiselect
                        v-model="user.business.social_fusion_settings.auto_sharing_settings.share_start_time"
                        :options="['12am', '1am', '2am', '3am', '4am', '5am', '6am', '7am','8am', '9am', '10am', '11am', '12pm', '1pm', '2pm', '3pm','4pm', '5pm', '6pm', '7pm', '8pm', '9pm', '10pm', '11pm']"
                        :show-labels="false"
                        class="mx-2 between-select"
                        placeholder="Select Start Time"
                    ></multiselect> and <multiselect
                        v-model="user.business.social_fusion_settings.auto_sharing_settings.share_end_time"
                        :options="['12am', '1am', '2am', '3am', '4am', '5am', '6am', '7am','8am', '9am', '10am', '11am', '12pm', '1pm', '2pm', '3pm','4pm', '5pm', '6pm', '7pm', '8pm', '9pm', '10pm', '11pm']"
                        :show-labels="false"
                        class="ml-2 between-select"
                        placeholder="Select End Time"
                    ></multiselect>
                    </div>
                    <div class="form-group d-flex align-items-center">
                      Limit to <input v-model="user.business.social_fusion_settings.auto_sharing_settings.share_daily_limit" type="number" min="1" class="form-control max-reviews mx-2" placeholder="No Limit" /> social media posts per day.
                    </div>
                    <div class="form-group d-flex align-items-center">
                      <span>Timezone:</span><multiselect
                          v-model="user.business.social_fusion_settings.auto_sharing_settings.timezone"
                          :options="user.business.timezones"
                          :show-labels="false"
                          class="timezone-select ml-2"
                          placeholder="Select Time Zone"
                      ></multiselect>
                    </div>
                    <b-form-group>
                      <b-button
                          variant="primary"
                          :disabled="loadingSettingsSave || (invalid && touched)"
                          type="submit"
                      >
                        <b-spinner v-if="loadingSettingsSave" small />
                        <span v-else>Save</span>
                      </b-button>
                    </b-form-group>
                  </form>
                </ValidationObserver>
              </b-tab>
              <b-tab v-if="user.business && user.business.is_social_review" title="History" @click="getHistory">
                <div v-if="retrieving_history" class="text-center">
                  <b-spinner small /> Retrieving History
                </div>
                <div v-else>
                  <div v-if="socialHistories.length < 1">
                    No Posting History
                  </div>
                  <b-table
                      v-else
                      :items="socialHistories"
                      :fields="datatable.columns"
                      :sort-by.sync="datatable.queries.sort_by"
                      :sort-desc.sync="datatable.queries.sort_desc"
                      :per-page="datatable.queries.per_page"
                      :no-local-sorting="true"
                      :borderless="true"
                  >
                    <div class="text-center text-danger my-2">
                      <b-spinner class="align-middle"></b-spinner>
                    </div>
                    <template v-slot:cell(link)="row">
                      <a v-if="row.item.platform === 'instagram'" :href="'https://instagram.com/p/' + row.item.page_id" target="_blank">https://instagram.com/p/{{row.item.page_id}}</a>
                      <a v-else-if="row.item.platform === 'facebook'" :href="'https://www.facebook.com/' + row.item.page_id" target="_blank">https://facebook.com/{{row.item.page_id}}</a>
                    </template>
                  </b-table>
                </div>
                <div v-if="!retrieving_history && socialHistories.length" class="row mt-4">
                  <div class="col">
                    <div class="float-left mt-2">
                      Total {{ totalsocialHistories }}
                    </div>
                    <div
                        class="dataTables_paginate paging_simple_numbers float-right"
                    >
                      <ul class="pagination pagination-rounded mb-0">
                        <b-pagination
                            v-model="datatable.queries.page"
                            :total-rows="totalsocialHistories"
                            :per-page="datatable.queries.per_page"
                            @change="onPageChanged"
                        ></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </b-tab>
              <b-tab v-if="user && user.business && user.business.is_text_requester" title="Review Requests">
                <b-card no-body>
                  <b-tabs style="margin-top:-20px" card nav-class="review-request-tabs">
                    <b-tab title="Web Hook" >
                      <div class="col mb-3" style="margin-top: -20px">
                        <label>Zapier/Pabbly Review Request Creation Automation</label><br />
                        <button v-b-toggle="'zapier-collapse'" type="button" class="btn btn-danger btn-xs mr-2">POST</button> {{zapierUrl}}
                        <b-card>
                          <h4 class="mt-0">Message Properties</h4>
                          <div class="row">
                            <div class="col-md-6">
                              <div class="mb-3">
    <span class="form-text">
        <button type="button" class="btn btn-outline-primary btn-xs">first_name</button>
        <button type="button" class="btn btn-grey btn-xs ml-2">string</button>
        <button type="button" class="btn btn-outline-danger btn-xs ml-2">max:250</button>
    </span>
                                <div class="form-label mt-3">The first name of the contact</div>
                                <hr />
                              </div>

                              <div class="mb-3">
    <span class="form-text">
        <button type="button" class="btn btn-outline-primary btn-xs">last_name</button>
        <button type="button" class="btn btn-grey btn-xs ml-2">string</button>
        <button type="button" class="btn btn-outline-danger btn-xs ml-2">max:250</button>
    </span>
                                <div class="form-label mt-3">The last name of the contact</div>
                                <hr />
                              </div>

                              <div class="mb-3">
    <span class="form-text">
        <button type="button" class="btn btn-outline-primary btn-xs">email</button>
        <button type="button" class="btn btn-grey btn-xs ml-2">string&lt;email&gt;</button>
    </span>
                                <div class="form-label mt-3">The email address of the contact</div>
                                <hr />
                              </div>

                              <div class="mb-3">
    <span class="form-text">
        <button type="button" class="btn btn-outline-primary btn-xs">cell_phone</button>
        <button type="button" class="btn btn-grey btn-xs ml-2">string&lt;valid cell phone number&gt;</button>
    </span>
                                <div class="form-label mt-3">The cell phone number of the contact</div>
                                <hr />
                              </div>

                              <div class="mb-3">
    <span class="form-text">
        <button type="button" class="btn btn-outline-primary btn-xs">country</button>
        <button type="button" class="btn btn-grey btn-xs ml-2">string</button>
        <button type="button" class="btn btn-outline-danger btn-xs ml-2">max:2</button>
    </span>
                                <div class="form-label mt-3">
                                  <strong>Optional:</strong> Specify the country code for the cell phone number. This is required when the phone number is not from the United States and is used by the phone validator to ensure accurate validation.</div>
                              </div>
                            </div>
                          </div>

                        </b-card>
                      </div>
                    </b-tab>
                    <b-tab title="Log" @click="getReviewRequestLog">
                      <div style="margin-top: -15px">
                        <div v-if="retrieving_review_request_log" class="text-center">
                          <b-spinner small /> Retrieving Log
                        </div>
                        <div v-else>
                          <div v-if="reviewRequestLog.length < 1">
                            No Log
                          </div>
                          <b-table
                              v-else
                              :items="reviewRequestLog"
                              :fields="reviewRequestDatatable.columns"
                              :sort-by.sync="reviewRequestDatatable.queries.sort_by"
                              :sort-desc.sync="reviewRequestDatatable.queries.sort_desc"
                              :per-page="reviewRequestDatatable.queries.per_page"
                              :no-local-sorting="true"
                              :borderless="true"
                          >
                            <div class="text-center text-danger my-2">
                              <b-spinner class="align-middle"></b-spinner>
                            </div>
                            <template v-slot:cell(content)="row">
                              <!-- eslint-disable-next-line-->
                              <div v-html="row.item.nl2br_html"></div>
                            </template>
                          </b-table>
                        </div>
                        <div v-if="!retrieving_review_request_log && reviewRequestLog.length" class="row mt-4">
                          <div class="col">
                            <div class="float-left mt-2">
                              Total {{ totalReviewRequestLog }}
                            </div>
                            <div
                                class="dataTables_paginate paging_simple_numbers float-right"
                            >
                              <ul class="pagination pagination-rounded mb-0">
                                <b-pagination
                                    v-model="reviewRequestDatatable.queries.page"
                                    :total-rows="totalReviewRequestLog"
                                    :per-page="reviewRequestDatatable.queries.per_page"
                                    @change="onReviewRequestLogPageChanged"
                                ></b-pagination>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </b-tab>
                  </b-tabs>
                </b-card>
              </b-tab>
              <b-tab v-if="!user.business.is_insta_stream && !user.business.is_reply_engine && !user.business.is_tap_engine && !user.business.is_social_fusion && !user.business.is_social_review && !user.business.is_text_requester" class="text-center" title="Settings">
                <h3><i class="uil uil-info-circle"></i>No Modules Are Enabled For You!</h3>
                <p>It looks like your agency haven't enabled any modules for your business yet. Please ask your agency to enable the features you need.</p>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
    <b-modal v-model="show_fb_modal" v-b-modal.modal-center :hide-header="!isImpersonating" :hide-footer="true" modal-class="location-connection-modal">
      <div class="container">
        <div class="row">
          <div class="col bg-white px-4 py-3">
            <label class="h3">Which business do you want to link to this location?</label>
            <div v-if="fb_valid">
              <div class="d-flex">
                <select v-if="user.business.facebook_token" v-model="user.business.facebook_token.fb_page_id" class="form-control" placeholder="Please Choose Page">
                  <option v-for="fb_account in user.business.facebook_token.accounts" :key="fb_account.id" :value="fb_account.id">{{fb_account.name}}</option>
                </select>
                <button v-if="user.business.facebook_token && user.business.facebook_token.fb_page_id !== initial_facebook_page" class="btn btn-primary ml-2" :disabled="user.business.facebook_token.is_saving" @click="updateFacebookPage">
                  <b-spinner v-if="user.business.facebook_token.is_saving" small />
                  <span v-else>Connect</span>
                </button>
              </div>
              <div v-if="igAccounts" class="mt-3">
                <strong class="d-block">Connected Instagram Account</strong>
                Name: {{igAccounts.name}}<br />
                Username: @{{igAccounts.username}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal v-model="show_modal" v-b-modal.modal-center modal-class="location-connection-modal" :hide-header="!isImpersonating" :hide-footer="true" :no-fade="true">
      <div class="container">
        <div class="row">
          <div class="col bg-white px-4 py-3">
            <ValidationObserver
                v-if="user && user.hasOwnProperty('google_accounts') && user.google_accounts.length"
                v-slot="{ handleSubmit, invalid, touched }"
                ref="settingsForm"
            >
              <form
                  class="authentication-form mt-2"
                  @submit.prevent="handleSubmit(updateSettings)"
              >
                <label class="h3">Which business do you want to link to this location?</label>
                <div v-if="user && user.google_accounts && user.google_accounts.length > 1" class="mb-1">
                  <select v-model="selected_account" class="form-control">
                    <option v-for="(account,i) in user.google_accounts" :key="i" :value="account" :selected="selected_account && selected_account.id === account.id" >
                      {{account.account_name}}
                    </option>
                  </select>
                </div>
                <div v-if="selected_account" class="mt-2">
                  <select v-model="default_location" class="form-control">
                    <option v-if="!default_location" disabled :value="null">Select Page</option>
                    <option v-for="(location,i) in selected_account.locations" :key="i" :value="location.id" :selected="location.is_default === 1">
                      {{location.title}} {{location.address !== null && location.address.hasOwnProperty('addressLines') && location.address.addressLines.length ? ' - ' + location.address.addressLines.join(' - ') : ''}}
                    </option>
                  </select>
                </div>
                <div class="text-right mt-4">
                  <b-form-group>
                    <b-button
                        variant="success"
                        :disabled="loadingSave || (invalid && touched)"
                        type="submit"
                        class="px-4"
                        :squared="true"
                    >
                      <b-spinner v-if="loadingSave" small />
                      <span v-else>Connect</span>
                    </b-button>
                  </b-form-group>
                </div>
              </form>
            </ValidationObserver>
            <div v-if="false && user.hasOwnProperty('tracking_numbers') && user.tracking_numbers.length">
              <h3 class="mt-5">Review Card Shipments</h3>
              <table class="table border table-striped" style="max-width: 450px">
                <thead>
                <tr>
                  <th>Date</th>
                  <th>Status</th>
                  <th>Tracking Information</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(tracking,i) in user.tracking_numbers" :key="i">
                  <td>
                    {{tracking.front_date}}
                  </td>
                  <td>
                    <span v-if="tracking.tracking_number" class="text-success">Shipped</span>
                    <span v-else class="text-danger">Not Shipped</span>
                  </td>
                  <td>
                    <div v-if="tracking.tracking_number">
                      <div>Carrier: {{tracking.carrier}}</div>
                      <div>Tracking Number: <a :href="tracking.tracking_url">{{tracking.tracking_number}}</a></div>
                    </div>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Vue from 'vue'
import Multiselect from 'vue-multiselect'
import 'vue-select/dist/vue-select.css';
import ManageTeams from "@components/Common/ManageTeams.vue";

export default {
  components: {
    ManageTeams,
    Multiselect
  },

  data() {
    return {
      business: {
        social_settings:{
          auto_sharing_settings:{
            fb_enabled:false,
            ig_enabled:false,
            minimum_rating:5
          }
        },
        social_fusion_settings:{
          auto_sharing_settings:{
            fb_enabled:false,
            ig_enabled:false,
            email_enabled:false,
          }
        }
      },
      recipientSaving:false,
      isDisabled:true,
      loadingSave:false,
      selected_account:null,
      default_location:null,
      redirect_to_dashboard:false,
      user: {
        business: {
          settings:{},
          email_settings:{
            new_review_emails:null,
            new_social_post_emails:null,
            new_review_replied_emails:null
          },
          twilio_settings:{
            prequalify_accept_msg:'Thank you so much. Would you mind leaving us a review here - [google_review_link]?',
            prequalify_fail_msg:'Thank you for taking the time to let us know. You can check out our Google Business Profile Here - [google_business_profile_link]?',
            prequalify_initial_msg:'Please rate us by replying with a number from 1 to 5.'
          }
        },
      },
      initial_facebook_page:'',
      show_modal:false,
      show_fb_modal:false,
      loadingSettingsSave:false,
      time_array:['12am','1am'],
      connected_sites_tab:false,
      fb_valid:true,
      retrieving_history:false,
      retrieving_review_request_log:false,
      datatable: {
        items: [],
        columns: [
          { key: 'platform', label:'Platform', sortable: true },
          { key: 'front_date', label:'Posted Date', sortable: true},
          { key: 'link', label:'Link', class: 'link' },
        ],
        queries: {
          per_page: 10,
          page: 1,
          status: 'active',
          search: null,
          sort_by: 'created_at',
          sort_desc: false,
        },
      },
      reviewRequestDatatable: {
        items: [],
        columns: [
          { key: 'type', label:'Type', sortable: true },
          { key: 'front_date', label:'Sent Date', sortable: true},
          { key: 'sent_to', label:'Sent To' },
          { key: 'content', label:'Content'},
        ],
        queries: {
          per_page: 10,
          page: 1,
          status: 'active',
          search: null,
          sort_by: 'created_at',
          sort_desc: false,
        },
      }
    }
  },

  computed: {
    isAdmin(){
      return localStorage.getItem('main_access_role') === 'Admin';
    },
    zapierUrl() {
      return (
        process.env.VUE_APP_BASE_API +
          'api/v1/create-contact/' +
          this.user.business.unique_id +
          '-' +
          this.user.business.id
      );
    },
    isImpersonating() {
      return this.$store.getters['auth/isImpersonating']
    },
    gRedirectLink(){
      return (process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BASE_API : process.env.VUE_APP_BASE_API_LOCAL)+'api/v1/auth-redirect/'+this.user.id+'/'+this.business.id;
    },
    FBRedirectLink(){
      const apiUrl = process.env.VUE_APP_BASE_API + 'api/v1';
      return apiUrl+'/fb-redirect/'+this.user.id+'/'+this.business.id;
    },
    IGRedirectLink(){
      const apiUrl = process.env.VUE_APP_BASE_API + 'api/v1';
      return apiUrl+'/ig-redirect/'+this.user.id+'/'+this.business.id;
    },
    igAccounts() {
      if (this.user?.business?.is_social_review && this.user?.business?.facebook_token?.accounts) {
        const foundAccount = this.user.business.facebook_token.accounts.find(o => o.id === this.user.business.facebook_token.fb_page_id);
        return foundAccount ? foundAccount.instagram_business_account : null;
      }
      return null;
    },
    FBLongLivedToken(){
      if (this.user?.business?.is_social_review && this.user?.business?.facebook_token?.accounts) {
        const foundAccount = this.user.business.facebook_token.accounts.find(o => o.id === this.user.business.facebook_token.fb_page_id);
        return foundAccount ? foundAccount.access_token : null;
      }
      return null;
    },
    socialHistories(){
      return this.$store.getters['socialHistories/all'];
    },
    totalsocialHistories(){
      return this.$store.getters['socialHistories/total'];
    },
    reviewRequestLog(){
      return this.$store.getters['reviewRequestLog/all'];
    },
    totalReviewRequestLog(){
      return this.$store.getters['reviewRequestLog/total'];
    },
    fromFacebook(){
      return this.$route.query.from_fb === 'true';
    },
    hasAccessTokenIssues() {
      return this.selected_account && this.selected_account.locations.some(account => account.has_access_token_issues);
    }
  },

  mounted() {
    this.user = Vue.util.extend({}, this.$store.getters['auth/user']);
    if(this.user.google_accounts.length === 1){
      this.selected_account = this.user.google_accounts[0];
    }else if(this.user.google_accounts.length > 1){
      const locationAccount = this.user.google_accounts.filter(acct => {
        return acct.locations.find(loc => {
          if(loc.is_default){
            this.default_location = loc.id;
          }
          return loc.is_default;
        })
      });
      if(locationAccount.length){
        this.selected_account = locationAccount[0];
      }
    }
    if(this.selected_account) {
      this.selected_account.locations.forEach(loc => {
        if (loc.is_default === 1) {
          this.default_location = loc.id;
        }
      })
    }
    this.business = Vue.util.extend({}, this.user.business);
    this.show_modal = this.user && this.user.google_accounts && this.user.google_accounts.length && !this.default_location;
    this.show_fb_modal = this.fromFacebook && this.user?.business?.facebook_token?.accounts.length > 0 && this.user.business?.facebook_token?.fb_page_id === null;

    if(this.default_location == null){
      this.redirect_to_dashboard = true;
    }
    if(window.location.search.includes('t=cs')){
      this.connected_sites_tab = true;
    }
    // Push people to connected Sites tab if the token doesn't exist
    if(this.user && this.user.google_accounts && this.user.google_accounts.length && this.default_location && this.user?.business?.is_social_review && this.user?.business?.facebook_token === null){
      this.connected_sites_tab = true;
    }
    if(this.user?.business?.is_social_review && this.user?.business?.facebook_token) {
      this.getCurrentFBConnection();
      this.initial_facebook_page = this.user.business.facebook_token ? this.user.business.facebook_token.fb_page_id : null;
    }
    if(this.user?.business?.is_social_fusion && this.user?.business?.facebook_token) {
      this.getCurrentFBConnection();
      this.initial_facebook_page = this.user.business.facebook_token ? this.user.business.facebook_token.fb_page_id : null;
    }
  },

  methods: {
    phoneUpdated(phone){
      this.user.business.twilio_settings.phoneObject = phone;
    },
    onPageChanged(page) {
      this.datatable.queries.page = page
      this.getHistory();
    },
    onReviewRequestLogPageChanged(page) {
      this.reviewRequestDatatable.queries.page = page
      this.getReviewRequestLog();
    },
    updateFacebookPage(){
      this.user.business.facebook_token.is_saving = true;
      const requestParam = {
        business_id: this.user.business.id,
        fb_page_id:this.user.business.facebook_token.fb_page_id,
        fb_page_access_token:this.FBLongLivedToken,
        token_id:this.user.business.facebook_token.id
      }
      this.$store
          .dispatch(`business/setFBPage`, requestParam)
          .then(() => {
            this.initial_facebook_page = requestParam.fb_page_id;
            this.user.business.facebook_token.is_saving = false;
            this.show_fb_modal = false;
          })
          .catch((err) => {
            console.error(err);
            this.user.business.facebook_token.is_saving = false
            this.show_fb_modal = false;
          })
    },
    getCurrentFBConnection(){
      this.$store
          .dispatch(`business/getCurrentConnection`, {
            token_id: this.user.business.facebook_token.id
          })
          .then((resp) => {
            this.user.business.facebook_token.accounts = resp;
          })
          .catch((err) => {
            this.fb_valid = false;
            console.error(err);
          })
    },
    getHistory(){
      this.retrieving_history = true;
      this.$store
          .dispatch(`socialHistories/getAll`, {
            queries: this.datatable.queries,
            page: 'index',
            businessId: this.user.business.id
          })
          .then((resp) => {
            console.log(resp);
            this.retrieving_history = false;
          })
          .catch((err) => {
            this.retrieving_history = false;
            console.error(err);
          })
    },
    getReviewRequestLog(){
      this.retrieving_review_request_log = true;
      this.$store
          .dispatch(`reviewRequestLog/getAll`, {
            queries: this.reviewRequestDatatable.queries,
            page: 'logs',
            businessId: this.user.business.id
          })
          .then((resp) => {
            console.log(resp);
            this.retrieving_review_request_log = false;
          })
          .catch((err) => {
            this.retrieving_review_request_log = false;
            console.error(err);
          })
    },
    updateEmailSettings(){
      console.log(this.recipientSaving);
      this.recipientSaving = true;
      console.log(this.recipientSaving);
      this.$store
          .dispatch('business/editSettings', {
            email_settings:{
              new_review_emails:this.user.business.email_settings.new_review_emails,
              new_social_post_emails:this.user.business.email_settings.new_social_post_emails,
              new_review_replied_emails:this.user.business.email_settings.new_review_replied_emails,
            },
            business_id:this.user.business.id,
            settings_form:'recipient_form_settings'
          })
          .then((resp) => {
            this.recipientSaving = false;
            console.log(resp);
          })
          .catch((err) => {
            console.error(err);
            this.recipientSaving = false
          })
    },
    updateReportSettings(){
      this.loadingSave = true
      const requestParam = {
        business_id: this.user.business.id,
        settings: this.user.business.settings
      }
      this.$store
          .dispatch('business/editSettings', requestParam)
          .then(() => {
            this.loadingSave = false;
          })
          .catch((err) => {
            console.error(err);
            this.loadingSave = false
          })
    },
    updateTwilioSettings(){
      this.loadingSave = true
      const requestParam = {
        business_id: this.user.business.id,
        settings: this.user.business.twilio_settings
      }
      this.$store
          .dispatch('business/editTwilioSettings', requestParam)
          .then(() => {
            this.loadingSave = false;
          })
          .catch((err) => {
            console.error(err);
            this.loadingSave = false
          })
    },
    updateSocialSettings(){
      this.loadingSettingsSave = true;
      const requestParam = {
        business_id: this.user.business.id,
        settings: this.user.business.social_settings
      }
      this.$store
          .dispatch('business/editSocialSettings', requestParam)
          .then(() => {
            this.loadingSettingsSave = false;
          })
          .catch((err) => {
            console.error(err);
            this.loadingSettingsSave = false
          })
    },
    updateSocialFusionSettings(){
      this.loadingSettingsSave = true;
      const requestParam = {
        business_id: this.user.business.id,
        settings: this.user.business.social_fusion_settings
      }
      this.$store
          .dispatch('business/editSocialFusionSettings', requestParam)
          .then(() => {
            this.loadingSettingsSave = false;
          })
          .catch((err) => {
            console.error(err);
            this.loadingSettingsSave = false
          })
    },
    updateSettings() {
      this.loadingSave = true
      const requestParam = {
        selectedAccount: this.selected_account.id,
        defaultLocation: this.default_location,
        business_id: this.user.business.id
      }
      this.$store
        .dispatch('business/editSettings', requestParam)
        .then(() => {
          this.loadingSave = false;
          if(this.default_location !== null && this.redirect_to_dashboard){
            this.$router.push({name:'business.dashboard'});
          }
        })
        .catch((err) => {
          console.error(err);
          this.loadingSave = false
        })
    },
    handleChange() {
      if (this.user.business.social_fusion_settings.auto_sharing_settings.fb_enabled || this.user.business.social_fusion_settings.auto_sharing_settings.ig_enabled) {
        if(this.user.business.social_fusion_settings.auto_sharing_settings.email_enabled){
          this.$set(this.user.business.social_fusion_settings.auto_sharing_settings, 'email_enabled', false);
        }
      }
    },
    handleEmailChange() {
      if (this.user.business.social_fusion_settings.auto_sharing_settings.email_enabled) {
        this.$set(this.user.business.social_fusion_settings.auto_sharing_settings, 'fb_enabled', false);
        this.$set(this.user.business.social_fusion_settings.auto_sharing_settings, 'ig_enabled', false);
      }
    }
  },
}
</script>
<style>
.review-request-tabs .nav-item a.nav-link:not(.active) {
  color: #fff !important;
}
</style>
<style lang="scss" scoped>
.fb-blue{
  color: #1877F2;
}
.insta-bg{
  background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
  -webkit-background-clip: text;
  /* Also define standard property for compatibility */
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
  .large-icons{
    font-size: 5em;
    margin-bottom: -15px;
    display: block;
  }
  .btn-grey{
    background: #656464;
    opacity: .7;
    &:hover{
      color: #fff;
      opacity: 1;
    }
  }
  .max-reviews{
    max-width: 100px;
  }
.max-rating{
  max-width: 120px;
}
  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {

    opacity: 1;

  }
  .between-select{
    max-width: 250px;
  }
  .days-select{
    max-width: 430px;
  }
  .days-select[data-v-cf713e9c]{
    box-sizing: content-box;
    display: block;
    position: relative;
    width: 100%;
    min-height: 40px;
    text-align: left;
    color: #35495e;
  }
  .timezone-select{
    max-width: 400px;
  }
</style>
<style lang="scss">
$white: #fff;
$google-blue: #4285f4;
$button-active-blue: #1669F2;

.google-btn {
  width: 225px;
  height: 42px;
  background-color: $google-blue;
  border-radius: 2px;
  box-shadow: 0 3px 4px 0 rgba(0,0,0,.25);
  .google-icon-wrapper {
    position: absolute;
    margin-top: 1px;
    margin-left: 1px;
    width: 40px;
    height: 40px;
    border-radius: 2px;
    background-color: $white;
  }
  .google-icon {
    position: absolute;
    margin-top: 11px;
    margin-left: 11px;
    width: 18px;
    height: 18px;
  }
  .btn-text {
    float: right;
    margin: 11px 11px 0 0;
    color: $white;
    font-size: 14px;
    letter-spacing: 0.2px;
    font-family: "Roboto";
  }
  &:hover {
    box-shadow: 0 0 6px $google-blue;
  }
  &:active {
    background: $button-active-blue;
  }
}
.time-td{
  .b-form-spinbutton{
    button{
      color: #222;
    }
  }
}
.location-connection-modal {

  .modal-dialog {
    max-width: 100%;
    margin: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100vh;
    display: flex;
    position: fixed;
    z-index: 100000;
    border-radius: 0;
    .modal-header, .modal-content, .modal-footer {
      border-radius: 0;
      border: 0;
    }
    .modal-content{
      background:#edeff4;
    }
    select{
      background: #f3f8fb;
    }
  }
}

.input-text {
  min-height: 40px;
  display: block;
  padding: 8px 40px 0 8px;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  background: #fff;
  font-size: 14px;
  overflow: hidden;
  height: 100px;
  margin-left: 20px;
  width: 40%;
}

</style>
